import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import {  Container,Row,Col,Card,Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Layout from "../components/layout"


import Bgimg from "../images/bg.inline.svg"
import Modal from "../components/modal"

import Accordion from 'react-bootstrap/Accordion'

const HomepageTemplate = ({data, pageContext: {lang}}) =>  (
  <Layout wpLang={lang} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
    <Container className="px-0 fluid">
      <Row className="flex-middle hero">
        <Col xs={12} md={6} className="video-play">
          <Img fluid={data.headerImg.childImageSharp.fluid} />
            <div className="pop-up-video">
            <Img fluid={data.play.childImageSharp.fluid} />
           </div>
        <Modal/> 
        </Col>
        <Col xs={12} md={4} className="above-text pr-0 pr-md-5 pl-0 pl-md-5">
          <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.hero_title }} />
          <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.hero_secondary }} />
        </Col>
      </Row>
    
    <Row className="how-it-works" id="how_it_works">
        <Col xs={12}>
        <h2 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_title }} />
            <div className="how-list"> 
            <span>01</span>
            <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_1_title }} />
            <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_1_content }} />
<Img fluid={data.illustration4.childImageSharp.fluid}/>
            </div>
    
            <div className="how-list"> <span>02</span>
            <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_2_title }} />
            <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_2_content }} />
   <Img fluid={data.illustration5.childImageSharp.fluid}/>
            </div>

            <div className="how-list"> <span>03</span>
            <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_3_title }} />
            <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_it_works_3_content }} />
        <Img fluid={data.illustration6.childImageSharp.fluid}/>
            </div>
    
        </Col>
      </Row>
        <Row className="how-to-get-verified-pre" id="how_to_get_verified">
            <div className="verif-wrap">
                <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.get_verifyed_title }} />
                <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.get_verifyed_desc }} />
                
                <div className="id-image"><Img fluid={data.id.childImageSharp.fluid}/></div>
            </div>
        </Row>                
        <Row className="get-verfied">
                <div  className="col-lg-12">
                    <h2 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verified___title }} />
                    <div  className="how-list"> <span>01</span>
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verifyed_1_title }} />
                        <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verifyed_1_desc }} />
                        <Img fluid={data.illustration1.childImageSharp.fluid}/>
                    </div>
                    <div  className="how-list"> <span>02</span>
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verifyed_2_title }} />
                        <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verifyed_2_desc }} />
                        <Img fluid={data.illustration2.childImageSharp.fluid}/>
                    </div>
                    <div  className="how-list"> <span>03</span>
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verifyed_3_title }} />
                        <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_get_verifyed_3_desc }} />
                        <Img fluid={data.illustration3.childImageSharp.fluid}/>
                    </div>
                </div>
            <div className="verified-bg"><Bgimg/></div>
        </Row>
        
    <Row className="country">
      <BackgroundImage fluid={data.globe.childImageSharp.fluid} style={{backgroundSize: '100% auto'}}>
          <Container>
            <Col md={{ span: 6, offset: 6 }}>
              <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.available }} />
              <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.available_desc }} />
            </Col>
          </Container>
      </BackgroundImage>
    </Row>
<section className="accordion-section clearfix mt-3 pt-md-5 pb-md-5" id="faq">
      <div className="container">
            <Row>
                <Col>
                    <h2 className="faq-title" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.faq_title}} />
                </Col>
                <div className="col">
                 
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            {data.wordpressPage.acf.accordion_1_title_custom}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body><p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.accordion_1_content_custom }}/></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
      
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            {data.wordpressPage.acf.accordion_2_title_custom}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body><p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.accordion_2_content_custom }}/></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
      
                          <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="3">
                            {data.wordpressPage.acf.accordion_3_title_custom}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body><p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.accordion_3_content_custom }}/></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
      
                          <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="4">
                            {data.wordpressPage.acf.accordion_4_title_custom}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body><p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.accordion_4_content_custom }}/></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                   
                </div>
            </Row>
        </div>
    </section>
    </Container>

  </Layout>
)
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "home"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      hero_title
      hero_secondary
      how_it_works_title
      available
      available_desc
        accordion_1_title_custom
        accordion_2_title_custom
        accordion_3_title_custom
        accordion_4_title_custom
        accordion_1_content_custom
        accordion_2_content_custom
        accordion_3_content_custom
        accordion_4_content_custom
        faq_title
      get_verifyed_title
      get_verifyed_desc
      how_it_works_1_content
      how_it_works_1_title
      how_it_works_2_title
      how_to_get_verifyed_3_title
      how_to_get_verifyed_3_desc
      how_to_get_verifyed_2_title
      how_to_get_verifyed_2_desc
      how_to_get_verifyed_1_title
      how_to_get_verifyed_1_desc
      how_to_get_verified___title
      how_it_works_3_title
      how_it_works_3_content
      how_it_works_2_content
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    headerImg: file(relativePath: { eq: "above-min.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustration1: file(relativePath: { eq: "Illustr1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustration2: file(relativePath: { eq: "Illustr2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustration3: file(relativePath: { eq: "Illustr3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    illustration4: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustration5: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    illustration6: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    globe: file(relativePath: { eq: "globe.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    id: file(relativePath: { eq: "id-kaart.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`
export default HomepageTemplate